import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkAnalytics: React.FC<SvgIconProps> = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <defs>
            <clipPath id="wk-reports-clipPath">
                <rect
                    id="Rectangle_3429"
                    data-name="Rectangle 3429"
                    width="25"
                    height="20"
                    transform="translate(0 -0.208)"
                    fill="none"
                />
            </clipPath>
        </defs>
        <g id="Group_10968" data-name="Group 10968" transform="translate(-3218 -805)">
            <rect
                id="Rectangle_3450"
                data-name="Rectangle 3450"
                width="25"
                height="25"
                transform="translate(3218 805)"
                fill="none"
            />
            <g id="Group_9361" data-name="Group 9361" transform="translate(3218 807.208)">
                <g
                    id="Group_9360"
                    data-name="Group 9360"
                    transform="translate(0 0)"
                    clipPath="url(#wk-reports-clipPath)">
                    <path
                        id="Path_5019"
                        data-name="Path 5019"
                        d="M0,19.8H25V0H0ZM1.389,1.389H23.611V8.157l-6.589,4.178a1.8,1.8,0,0,0-1.689-.251L10.661,6.631a1.835,1.835,0,0,0,.219-.859,1.869,1.869,0,0,0-3.737,0,1.844,1.844,0,0,0,.093.557L1.389,11.114ZM23.611,18.406H1.389v-5.5L8.117,7.4a1.762,1.762,0,0,0,1.49.132l4.674,5.454a1.846,1.846,0,0,0-.218.858,1.869,1.869,0,1,0,3.7-.339L23.611,9.8Z"
                        transform="translate(0 0)"
                        fill="currentColor"
                    />
                </g>
            </g>
            <rect width="40" height="15" fill="#F0D8A8" rx="7.5" ry="7.5" x="5" y="2.5" />
            <text
                x="25"
                y="12"
                fontSize="8"
                fontWeight="bold"
                fill="black"
                textAnchor="middle"
                dominantBaseline="middle">
                BETA
            </text>
        </g>
    </SvgIcon>
);
